<template>
  <reaview-component />
</template>

<script>
import ReaviewComponent from "../../components/compliance/Review.vue";

export default {
  components: {
    ReaviewComponent,
  },
};
</script>

<style></style>
