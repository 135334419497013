<template>
  <div>
    <vs-card>
      <div class="flex flex-wrap m-5" v-if="page === 1">
        <div class="w-full">
          <div
            :class="
              complianceDetails.status &&
              complianceDetails.status.toLowerCase() +
                ' rounded-full text-sm text-center px-3 py-1 inline-block font-semibold'
            "
          >
            {{ getStatusData(complianceDetails.status) }}
          </div>
        </div>
        <div class="w-full mt-5">
          <div class="flex flex-wrap justify-between w-full lg:flex-no-wrap">
            <div class="w-full mb-5 lg:w-1/2 lg:mb-0">
              <p class="text-sm">
                {{ `${complianceMonth} Compliance` }}
              </p>
              <p class="hidden text-2xl font-semibold lg:block">
                {{ complianceDetails.clinicName }}
              </p>
              <h3 class="lg:hidden">{{ complianceDetails.clinicName }}</h3>
              <p>Files supported for upload: PNG, JPG, JPEG, HEIC, PDF, MOV, MP4 </p>
            </div>

            <div class="flex flex-shrink">
              <div>
                <vs-button color="primary" @click="saveImages">Save</vs-button>
              </div>
              <vs-select
                placeholder="Set outcome"
                class="ml-2"
                id="compliance-list-clinic"
                autocomplete
                v-model="status"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in statusList"
                />
              </vs-select>
            </div>
          </div>

          <vs-divider />
          <div class="flex flex-wrap">
            <div class="w-full mb-5 mr-5 lg:w-auto lg:mb-0">
              <p class="mb-2 text-base font-normal">Details</p>
              <p class="mb-1 text-sm">
                {{ complianceDetails.clinicName }}
              </p>
              <p class="mb-1 text-sm">
                {{ complianceDetails.contactNumber }}
              </p>
              <p class="mb-1 text-sm">
                {{ complianceDetails.bestContactEmail }}
              </p>

              <div class="flex mt-2" v-if="complianceDetails.bookingDate">
                <div>
                  <h4 class="mb-2">Booking confirmed</h4>
                  <h4 class="mb-2">Details</h4>
                  <p>
                    {{ complianceDetails.bookingDate }}
                  </p>
                </div>
                <!-- <div class="ml-5" v-if="status === 'In Review'">
              <vs-button
                size="lg"
                color="success"
                type="border"
                @click="gotoBookingLink"
                >Booking Link<Link:touch></Link:touch
              ></vs-button>
            </div> -->
                <!-- <div class="ml-5">
              <vs-button
                size="lg"
                color="success"
                type="border"
                @click="rescheduleBooking"
                >Reschedule</vs-button
              >
            </div> -->
                <!-- <div class="ml-5">
              <vs-button
                size="lg"
                color="danger"
                type="border"
                @click="cancelBooking"
                >Cancel Booking</vs-button
              >
            </div> -->
              </div>
            </div>

            <!-- <div class="mr-5">
              <h4 v-if="complianceDetails.bookingDate !== null" class="mb-2">
                Calendar booking
              </h4>
              <p v-else>No Booking Date</p>
              <p v-if="complianceDetails.bookingDate !== null">
                {{ complianceDetails.bookingDate }}
              </p>
            </div> -->

            <!-- <div v-if="complianceDetails.status === 'Overdue'">
              <vs-button size="lg" color="success" type="border"
                >Reschedule</vs-button
              >
            </div> -->
            <div
              class="w-full p-2 rounded background-gray history-container lg:w-auto"
              v-if="previousComplianceDetails.createdAt"
            >
              <p class="text-xs">
                Summary of last month
                {{ formatDate(previousComplianceDetails.createdAt, false) }}
              </p>
              <vs-list>
                <vs-list-item
                  icon="fiber_manual_record"
                  :subtitle="`Status: ${getStatus(
                    previousComplianceDetails.status
                  )}`"
                >
                </vs-list-item>
                <!-- <vs-list-item
                  :key="index"
                  v-for="(notes, index) in handoverNotes"
                  icon="radio_button_checked"
                  :subtitle="notes"
                >
                </vs-list-item> -->
              </vs-list>
              <p v-if="previousRequestChanges.drug.length > 0">
                Drugbook changes requested
              </p>
              <vs-list>
                <vs-list-item
                  :key="index"
                  v-for="(notes, index) in previousRequestChanges.drug"
                  icon="fiber_manual_record"
                  :subtitle="notes.requestNote"
                >
                </vs-list-item>
              </vs-list>
              <p v-if="previousRequestChanges.fridge.length > 0">
                Fridge changes requested
              </p>
              <vs-list v-if="previousRequestChanges.fridge.length > 0">
                <vs-list-item
                  :key="index"
                  v-for="(notes, index) in previousRequestChanges.fridge"
                  icon="fiber_manual_record"
                  :subtitle="notes.requestNote"
                >
                </vs-list-item>
              </vs-list>
              <p v-if="previousRequestChanges.stock.length > 0">
                Stock changes requested
              </p>
              <vs-list v-if="previousRequestChanges.stock.length > 0">
                <vs-list-item
                  :key="index"
                  v-for="(notes, index) in previousRequestChanges.stock"
                  icon="fiber_manual_record"
                  :subtitle="notes.requestNote"
                >
                </vs-list-item>
              </vs-list>
              <p v-if="previousRequestChanges.emergency.length > 0">
                Emergency medication changes requested
              </p>
              <vs-list v-if="previousRequestChanges.emergency.length > 0">
                <vs-list-item
                  :key="index"
                  v-for="(notes, index) in previousRequestChanges.emergency"
                  icon="fiber_manual_record"
                  :subtitle="notes.requestNote"
                >
                </vs-list-item>
              </vs-list>
              <p v-if="previousRequestChanges.videoTour.length > 0">
                Clinic tour changes requested
              </p>
              <vs-list v-if="previousRequestChanges.videoTour.length > 0">
                <vs-list-item
                  :key="index"
                  v-for="(notes, index) in previousRequestChanges.videoTour"
                  icon="fiber_manual_record"
                  :subtitle="notes.requestNote"
                >
                </vs-list-item>
              </vs-list>
            </div>
          </div>
          <vs-divider />
          <div>
            <div>
              <vs-list>
                <vs-list-item
                  :key="index"
                  v-for="(note, index) in generalNotes"
                  icon="radio_button_checked"
                  :subtitle="`${note.note} -   ${formatDate(note.createdAt)}`"
                >
                </vs-list-item>
              </vs-list>
            </div>

            <div
              class="flex flex-no-wrap items-center w-full cursor-pointer"
              style="color: #61676f"
              @click="showGeneralChanges = !showGeneralChanges"
            >
              <vs-icon icon="add" size="medium" color="#0E4333" />Add Internal
              Notes
            </div>

            <div class="w-1/2" v-if="showGeneralChanges">
              <vs-textarea
                label="Add your notes for next review."
                v-model="generalNotesChanges"
              />
              <vs-button
                type="border"
                color="primary"
                @click="saveGeneralNotes()"
                >Save</vs-button
              >
            </div>
          </div>

          <vs-divider />
        </div>
        <vs-popup
          :active.sync="popUpDelete"
          title="Delete Confirmation"
          class="overflow-hidden deleteContainer"
        >
          <p align="center">
            {{ `Are you sure you want to delete this ${selectedFile}?` }}
          </p>
          <br />
          <vs-row
            vs-align="flex-start"
            vs-type="flex"
            vs-justify="left"
            vs-w="12"
            vs-mt="6"
          >
            <vs-col vs-type="flex" vs-align="left" vs-w="6">
              <div class="w-full m-5" align="right">
                <vs-button
                  size="lg"
                  color="primary"
                  @click="
                    removePreview({
                      Location:
                        selectedFile === 'image'
                          ? images[imagePreviewSrc.from][imagePreviewSrc.index]
                              .photoLink
                          : videos[selectedVideo.group][selectedVideo.index]
                              .videoLink,
                      from:
                        selectedFile === 'image'
                          ? imagePreviewSrc.from
                          : videos[selectedVideo.group][selectedVideo.index]
                              .group,
                    })
                  "
                  >Yes</vs-button
                >
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <vs-button size="lg" color="danger" @click="popUpDelete = false"
                  >Cancel</vs-button
                >
              </div>
            </vs-col>
          </vs-row>
        </vs-popup>
        <vs-popup
          style="overflow: hidden; max-height: 100%"
          class="overflow-hidden resizeImgPopUp"
          title="Image Preview"
          :active.sync="popupImageActivo"
        >
          <div
            class="w-full"
            v-if="imagePreviewSrc.from.length > 0"
            style="
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            "
          >
            <div class="w-full" style="height: 90%">
              <template
                v-if="
                  images[imagePreviewSrc.from][
                    imagePreviewSrc.index
                  ].photoLink.indexOf('pdf') !== -1
                "
              >
                <img
                  style="max-height: 60vh; padding-bottom: 10px"
                  class="object-contain w-full"
                  :key="
                    images[imagePreviewSrc.from][imagePreviewSrc.index].index
                  "
                  src="@/assets/images/icon-pdf.svg"
                />
              </template>
              <img
                v-else
                style="max-height: 60vh; padding-bottom: 10px"
                class="object-contain w-full"
                :key="images[imagePreviewSrc.from][imagePreviewSrc.index].index"
                :src="
                  images[imagePreviewSrc.from][imagePreviewSrc.index].photoLink
                "
              />
            </div>
            <div class="flex flex-no-wrap justify-between w-full">
              <div class="flex flex-row gap-3">
                <a
                  :href="
                    images[imagePreviewSrc.from][imagePreviewSrc.index]
                      .photoLink
                  "
                  :download="
                    getDownloadName(
                      images[imagePreviewSrc.from][imagePreviewSrc.index]
                    )
                  "
                  target="_blank"
                >
                  <vs-button color="success" icon="file_download"
                    >Download</vs-button
                  >
                </a>
                <vs-button
                  :disabled="images[this.imagePreviewSrc.from].length === 0"
                  size="lg"
                  color="danger"
                  class="text-right"
                  @click="displayRemoveModal({ fileType: 'image' })"
                  >Remove</vs-button
                >
              </div>
              <!-- <div>
              </div> -->
              <div class="flex">
                <vs-button
                  color="success"
                  type="flat"
                  :radius="true"
                  @click="back"
                  icon="arrow_back_ios"
                />
                <vs-button
                  color="success"
                  type="flat"
                  :radius="true"
                  @click="forward"
                  icon="arrow_forward_ios"
                />
              </div>
            </div>
          </div>
        </vs-popup>
        <!-- <div class="w-full">
          <div class="flex flex-no-wrap w-full mt-5">
            <div class="w-full">
              <h3 class="mb-2">Handover notes</h3>
              <p>Handover notes from last meeting</p>
              <div class="flex flex-wrap w-full p-2 mt-2">
                <vs-list>
                  <vs-list-item
                    :key="index"
                    v-for="(notes, index) in handoverNotes"
                    icon="radio_button_checked"
                    :subtitle="notes"
                  >
                  </vs-list-item>
                </vs-list>
              </div>
            </div>
          </div>
        </div>
        <vs-divider /> -->
        <div class="w-full">
          <div class="flex flex-no-wrap w-full mt-5">
            <div class="w-full">
              <div class="flex flex-no-wrap justify-between">
                <div>
                  <p class="text-base font-semibold">Drug Book</p>
                 <progress-bar class="mt-5 progress-bar-width" v-if="isDigitalDrubook" :month="monthCompliance" :year="yearCompliance" :clinicId="complianceDetails.clinicId"/>
                </div>
                <div
                  v-if="!isStocktake || !complianceDrugbook.length"
                  class="vs-con-loading__container"
                  style="text-align: center; position: relative"
                >
                  <label
                      v-if="!isDigitalDrubook"
                      class="vs-component vs-button h-12 text-center px-6 text-sm vs-button-primary vs-button-border flex items-center"
                      style="
                        border: 1px solid #cccccc;
                        display: inline-block;
                        width: 100%;
                        border-radius: 5px;
                        padding: 10px;
                        border: 1px solid rgba(var(--vs-primary),1);
                        color: rgba(var(--vs-primary),1);
                      "
                    >
                      <span>
                        <span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span>
                        <span class="vs-button-text vs-button--text"> Upload </span>

                          <input
                            type="file"
                            :disabled="complianceDetails.status==='completed'"
                            @change="uploadImage($event, 'drug')"
                            id="drug-file-input"
                            accept="image/*,application/pdf,.heic"
                            hidden
                            multiple
                          />
                          <span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span>
                      </span>
                  </label>
                  <!-- <vs-button :disabled="complianceDetails.status==='completed'" v-else type="border" class="h-12 text-center py-2 text-sm" @click="genratePdfReport">
                      Export Drugbook
                    </vs-button> -->
                </div>
              </div>
              <div class="w-full">
                <div v-if="isStocktake && complianceDrugbook.length > 0">
                  <!-- <div v-if="totalTreatmentsWithNoEntry > 0 " class="p-2 rounded " style="background-color: rgba(234, 84, 85, 0.12);color: #EA5455; max-width: 909px;">
                    <p><b>Drugbook Records Pending</b> - You have {{totalTreatmentsWithNoEntry}} treatments without drugbook entries. Please complete the Drugbook for these treatments  in the mobile app first.</p>
                  </div> -->
                  <vs-table
                    maxHeight="50vh"
                    v-if="treatments === 0 && windowWidth > 1199"
                    ref="table"
                    :sst="true"
                    :data="complianceDrugbook"
                    noDataText="No Data Available"
                  >
                    <template slot="thead">
                      <vs-th
                        style="background-color: #bcd6ce"
                        sort-key="clinicName"
                        >PRODUCT NAME</vs-th
                      >
                      <vs-th
                        style="background-color: #bcd6ce"
                        sort-key="orderYear"
                        >DRUGBOOK BALANCE</vs-th
                      >
                      <vs-th
                        style="background-color: #bcd6ce"
                        sort-key="orderMonth"
                        >COUNTED QUANTITY</vs-th
                      >
                      <vs-th style="background-color: #bcd6ce" sort-key="status"
                        >VARIANCE</vs-th
                      >
                      <vs-th style="background-color: #bcd6ce" sort-key="status"
                        >NOTES</vs-th
                      >
                    </template>
                    <template slot-scope="{ data }">
                      <vs-tr
                        :data="tr"
                        :key="indextr"
                        v-for="(tr, indextr) in complianceDrugbook"
                      >
                        <vs-td :data="data[indextr].productName">{{
                          data[indextr].productName
                        }}</vs-td>
                        <vs-td :data="data[indextr].drugBookBalance">{{
                          data[indextr].drugBookBalance
                        }}</vs-td>
                        <vs-td :data="data[indextr].countedQuality">{{
                          data[indextr].countedQuality
                        }}</vs-td>
                        <vs-td
                          class="m-drugbook-variance"
                          :data="data[indextr].variance"
                        >
                          <p v-if="data[indextr].variance == 0">
                            {{
                              `${data[indextr].variance}${
                                data[indextr].unit === "ml"
                                  ? data[indextr].unit
                                  : "u"
                              }`
                            }}
                          </p>
                          <p v-else>
                            {{
                              `${
                                data[indextr].drugBookBalance >
                                data[indextr].countedQuality
                                  ? `-${data[indextr].variance}`
                                  : `+${Math.abs(data[indextr].variance)}`
                              }${
                                data[indextr].unit === "ml"
                                  ? data[indextr].unit
                                  : "u"
                              }`
                            }}
                          </p>
                        </vs-td>
                        <vs-td :data="data[indextr].notes">{{
                          data[indextr].notes
                        }}</vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                  <div v-if="treatments === 0 && windowWidth <= 1199">
                    <div
                      style="margin-bottom: 8px"
                      :key="indextr"
                      v-for="(tr, indextr) in complianceDrugbook"
                      class="rounded-lg shadow-md m-drugbook-wrapper"
                    >
                      <div class="m-drugbook-name">
                        {{ tr.productName }}
                      </div>
                      <div class="m-drugbook-row">
                        <span class="m-drugbook-label">Counted Quantity:</span>
                        <span class="m-drugbook-values">{{
                          tr.countedQuality
                        }}</span>
                      </div>
                      <div class="m-drugbook-row">
                        <span class="m-drugbook-label">Drugbook Quantity:</span>
                        <span class="m-drugbook-values">{{
                          tr.drugBookBalance
                        }}</span>
                      </div>
                      <div class="m-drugbook-row">
                        <span class="m-drugbook-label m-drugbook-variance"
                          >Variance:</span
                        >
                        <span class="m-drugbook-variance">
                          <span v-if="tr.variance == 0">
                            {{
                              `${`${Math.abs(tr.variance)}`}${
                                tr.unit === "ml" ? tr.unit : "u"
                              }`
                            }}
                          </span>
                          <span>{{
                            `${
                              tr.drugBookBalance > tr.countedQuality
                                ? `-${tr.variance}`
                                : `+${Math.abs(tr.variance)}`
                            }${tr.unit === "ml" ? tr.unit : "u"}`
                          }}</span>
                        </span>
                      </div>
                      <div class="m-drugbook-row">
                        <span class="m-drugbook-label">Notes:</span>
                        <span class="m-drugbook-notes">{{ tr.notes }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <carousel
                  v-else
                  :per-page="1"
                  :mouse-drag="false"
                  :perPage="6"
                  class="w-full"
                  navigationEnabled
                  :paginationEnabled="false"
                >
                  <!-- <slide
                    :key="index"
                    class="w-32 m-2"
                    v-for="(image, index) in images.drug"
                    @click="ImagePreview(image.Location, 'drug')"
                  > -->
                  <slide
                    :key="image.index"
                    class="p-5 md:w-1/6"
                    v-for="(image, index) in images.drug"
                  >
                    <div
                      :key="index"
                      v-if="image.photoLink.indexOf('pdf') < 0"
                      @click="
                        ImagePreview({
                          Location: image.photoLink,
                          from: 'drug',
                        })
                      "
                    >
                      <vs-image
                        class="object-cover w-full hover:shadow-lg hover-scale"
                        :key="image.index"
                        :src="image.photoLink"
                      />
                      <span>{{ formatDate(image.createdAt) }}</span>
                    </div>
                    <template
                      v-if="image.photoLink.indexOf('pdf') > -1"
                    >
                      <div
                      v-if="!isDigitalDrubook"
                        :key="index"
                        @click="DownloadPdf({ url: image.photoLink, name: 'drug' })"
                      >
                        <img
                          class="object-cover w-full cursor-pointer hover:shadow-lg hover-scale"
                          :key="image.index"
                          src="@/assets/images/icon-pdf.svg"
                        />
                        <div class="text-center">
                          {{complianceDetails.clinicName}} <br/>
                          {{` [Drugbook] - ${complianceMonth} `}}
                        </div>
                      </div>
                      <div
                        :key="index+'digital'"
                        v-else
                        @click="DownloadPdf({ url: image.photoLink, name: `${complianceDetails.clinicName} - Drugbook - Monthly Compliance` })"
                      >
                        <img
                          class="w-full hover:shadow-lg hover-scale object-cover cursor-pointer"
                          :key="image.index"
                          src="@/assets/images/icon-pdf.svg"
                        />
                      </div>
                    </template>
                  </slide>
                </carousel>
              </div>
              <div>
                <div>
                  <vs-list>
                    <vs-list-item
                      :key="index"
                      v-for="(note, index) in requestChanges.drug"
                      icon="radio_button_checked"
                      :subtitle="`${note.requestNote} -   ${formatDate(
                        note.createdAt
                      )}`"
                    >
                    </vs-list-item>
                  </vs-list>
                </div>
                <div
                  class="flex flex-no-wrap items-center w-full cursor-pointer"
                  style="color: #61676f"
                  @click="showDrugChanges = !showDrugChanges"
                >
                  <vs-icon icon="add" size="medium" color="#0E4333" />Request
                  Changes
                </div>
                <div class="w-1/2" v-if="showDrugChanges">
                  <vs-textarea
                    label="Add your notes for next review."
                    v-model="drugChanges"
                  />
                  <vs-button
                    type="border"
                    color="primary"
                    @click="saveChangeRequest('drug')"
                    >Save</vs-button
                  >
                </div>
              </div>
            </div>
          </div>
          <vs-divider />
          <div class="flex flex-no-wrap w-full mt-5">
            <div class="w-full">
              <div class="flex flex-no-wrap justify-between">
                <p class="text-base font-semibold">Fridge Temperatures</p>
                <div
                  class="vs-con-loading__container"
                  style="text-align: center; position: relative"
                >
                  <label
                      class="vs-component vs-button h-12 text-center px-6 text-sm vs-button-primary vs-button-border flex items-center"
                      style="
                        border: 1px solid #cccccc;
                        display: inline-block;
                        width: 100%;
                        border-radius: 5px;
                        padding: 10px;
                        border: 1px solid rgba(var(--vs-primary),1);
                        color: rgba(var(--vs-primary),1);
                      "
                    >
                    <span>
                      <span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span>
                      <span class="vs-button-text vs-button--text"> Upload </span>

                        <input
                          type="file"
                          @change="uploadImage($event, 'fridge')"
                          id="fridge-file-input"
                          accept="image/*,application/pdf,.heic"
                          hidden
                          multiple
                        />
                        <span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span>
                    </span>
                  </label>
                </div>
              </div>
              <div class="flex flex-wrap w-full">
                <carousel
                  :per-page="1"
                  :mouse-drag="false"
                  :perPage="6"
                  class="w-full"
                  navigationEnabled
                  :paginationEnabled="false"
                >
                  <slide
                    :key="image.index"
                    class="p-5 md:w-1/6"
                    v-for="(image, index) in images.fridge"
                  >
                    <div
                      :key="index"
                      v-if="image.photoLink.indexOf('pdf') < 0"
                      @click="
                        ImagePreview({
                          Location: image.photoLink,
                          from: 'fridge',
                        })
                      "
                    >
                      <vs-image
                        class="object-cover w-full hover:shadow-lg hover-scale"
                        :key="image.index"
                        :src="image.photoLink"
                      />
                      <span>{{ formatDate(image.createdAt) }}</span>
                    </div>
                    <div
                      :key="index"
                      v-if="image.photoLink.indexOf('pdf') > -1"
                      @click="
                        DownloadPdf({ url: image.photoLink, name: 'fridge' })
                      "
                    >
                      <img
                        class="object-cover w-full cursor-pointer hover:shadow-lg hover-scale"
                        :key="image.index"
                        src="@/assets/images/icon-pdf.svg"
                      />
                       <div class="text-center">
                          {{complianceDetails.clinicName}} <br/>
                          {{` [Drugbook] - ${complianceMonth} `}}
                        </div>
                    </div>
                  </slide>
                </carousel>
              </div>
              <div>
                <div>
                  <vs-list>
                    <vs-list-item
                      :key="index"
                      v-for="(note, index) in requestChanges.fridge"
                      icon="radio_button_checked"
                      :subtitle="`${note.requestNote} -   ${formatDate(
                        note.createdAt
                      )}`"
                    >
                    </vs-list-item>
                  </vs-list>
                </div>
                <div
                  class="flex flex-no-wrap items-center w-full cursor-pointer"
                  style="color: #61676f"
                  @click="showFridgeChanges = !showFridgeChanges"
                >
                  <vs-icon icon="add" size="medium" color="#0E4333" />Request
                  Changes
                </div>
                <div class="w-1/2" v-if="showFridgeChanges">
                  <vs-textarea
                    label="Add your notes for next review."
                    v-model="fridgeChanges"
                  />
                  <vs-button
                    type="border"
                    color="primary"
                    @click="saveChangeRequest('fridge')"
                    >Save</vs-button
                  >
                </div>
              </div>
            </div>
          </div>
          <vs-divider />
          <div class="flex flex-no-wrap w-full mt-5">
            <div class="w-full">
              <div class="flex flex-no-wrap justify-between">
                <p class="text-base font-semibold">Stock Photos</p>
                <div
                  class="vs-con-loading__container"
                  style="text-align: center; position: relative"
                >
                  <label
                      class="vs-component vs-button h-12 text-center px-6 text-sm vs-button-primary vs-button-border flex items-center"
                      style="
                        border: 1px solid #cccccc;
                        display: inline-block;
                        width: 100%;
                        border-radius: 5px;
                        padding: 10px;
                        border: 1px solid rgba(var(--vs-primary),1);
                        color: rgba(var(--vs-primary),1);
                      "
                    >
                    <span>
                      <span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span>
                      <span class="vs-button-text vs-button--text"> Upload </span>

                        <input
                          type="file"
                          @change="uploadImage($event, 'stock')"
                          id="stock-file-input"
                          accept="image/*,application/pdf,.heic"
                          hidden
                          multiple
                        />
                        <span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span>
                    </span>
                  </label>
                </div>
              </div>
              <div class="flex flex-wrap w-full">
                <!-- :navigate-to="someLocalProperty" -->
                <carousel
                  :per-page="1"
                  :mouse-drag="false"
                  :perPage="6"
                  class="w-full"
                  navigationEnabled
                  :paginationEnabled="false"
                >
                  <slide
                    :key="image.index"
                    class="p-5 md:w-1/6"
                    v-for="(image, index) in images.stock"
                  >
                    <div
                      :key="index"
                      v-if="image.photoLink.indexOf('pdf') < 0"
                      @click="
                        ImagePreview({
                          Location: image.photoLink,
                          from: 'stock',
                        })
                      "
                    >
                      <vs-image
                        class="object-cover w-full hover:shadow-lg hover-scale"
                        :key="image.index"
                        :src="image.photoLink"
                      />
                      <span>{{ formatDate(image.createdAt) }}</span>
                    </div>
                    <div
                      :key="index"
                      v-if="image.photoLink.indexOf('pdf') > -1"
                      @click="
                        DownloadPdf({ url: image.photoLink, name: 'stock' })
                      "
                    >
                      <img
                        class="object-cover w-full cursor-pointer hover:shadow-lg hover-scale"
                        :key="image.index"
                        src="@/assets/images/icon-pdf.svg"
                      />
                       <div class="text-center">
                          {{complianceDetails.clinicName}} <br/>
                          {{` [Drugbook] - ${complianceMonth} `}}
                        </div>
                    </div>
                  </slide>
                </carousel>
              </div>
              <div>
                <div>
                  <vs-list>
                    <vs-list-item
                      :key="index"
                      v-for="(note, index) in requestChanges.stock"
                      icon="radio_button_checked"
                      :subtitle="`${note.requestNote} -   ${formatDate(
                        note.createdAt
                      )}`"
                    >
                    </vs-list-item>
                  </vs-list>
                </div>
                <div
                  class="flex flex-no-wrap items-center w-full cursor-pointer"
                  style="color: #61676f"
                  @click="showStockChanges = !showStockChanges"
                >
                  <vs-icon icon="add" size="medium" color="#0E4333" />Request
                  Changes
                </div>
                <div class="w-1/2" v-if="showStockChanges">
                  <vs-textarea
                    label="Add your notes for next review."
                    v-model="stockChanges"
                  />
                  <vs-button
                    type="border"
                    color="primary"
                    @click="saveChangeRequest('stock')"
                    >Save</vs-button
                  >
                </div>
              </div>
            </div>
          </div>
          <vs-divider />
          <div class="flex flex-no-wrap w-full mt-5">
            <div class="w-full">
              <div class="flex flex-no-wrap justify-between">
                <p class="text-base font-semibold">Emergency Medication Photos</p>
                <div
                  class="vs-con-loading__container"
                  style="text-align: center; position: relative"
                >
                  <label
                      class="vs-component vs-button h-12 text-center px-6 text-sm vs-button-primary vs-button-border flex items-center"
                      style="
                        border: 1px solid #cccccc;
                        display: inline-block;
                        width: 100%;
                        border-radius: 5px;
                        padding: 10px;
                        border: 1px solid rgba(var(--vs-primary),1);
                        color: rgba(var(--vs-primary),1);
                      "
                    >
                    <span>
                      <span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span>
                      <span class="vs-button-text vs-button--text"> Upload </span>

                        <input
                          type="file"
                          @change="uploadImage($event, 'emergency')"
                          id="stock-file-input"
                          accept="image/*,application/pdf,.heic"
                          hidden
                          multiple
                        />
                        <span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span>
                    </span>
                  </label>
                </div>
              </div>
              <div class="flex flex-wrap w-full">
                <!-- :navigate-to="someLocalProperty" -->
                <carousel
                  :per-page="1"
                  :mouse-drag="false"
                  :perPage="6"
                  class="w-full"
                  navigationEnabled
                  :paginationEnabled="false"
                >
                  <slide
                    :key="image.index"
                    class="p-5 md:w-1/6"
                    v-for="(image, index) in images.emergency"
                  >
                    <div
                      :key="index"
                      v-if="image.photoLink.indexOf('pdf') < 0"
                      @click="
                        ImagePreview({
                          Location: image.photoLink,
                          from: 'emergency',
                        })
                      "
                    >
                      <vs-image
                        class="object-cover w-full hover:shadow-lg hover-scale"
                        :key="image.index"
                        :src="image.photoLink"
                      />
                      <span>{{ formatDate(image.createdAt) }}</span>
                    </div>
                    <div
                      :key="index"
                      v-if="image.photoLink.indexOf('pdf') > -1"
                      @click="
                        DownloadPdf({ url: image.photoLink, name: 'emergency' })
                      "
                    >
                      <img
                        class="object-cover w-full cursor-pointer hover:shadow-lg hover-scale"
                        :key="image.index"
                        src="@/assets/images/icon-pdf.svg"
                      />
                       <div class="text-center">
                          {{complianceDetails.clinicName}} <br/>
                          {{` [Drugbook] - ${complianceMonth} `}}
                        </div>
                    </div>
                  </slide>
                </carousel>
              </div>
              <div>
                <div>
                  <vs-list>
                    <vs-list-item
                      :key="index"
                      v-for="(note, index) in requestChanges.emergency"
                      icon="radio_button_checked"
                      :subtitle="`${note.requestNote} -   ${formatDate(
                        note.createdAt
                      )}`"
                    >
                    </vs-list-item>
                  </vs-list>
                </div>
                <div
                  class="flex flex-no-wrap items-center w-full cursor-pointer"
                  style="color: #61676f"
                  @click="showEmergencyMedicationChanges = !showEmergencyMedicationChanges"
                >
                  <vs-icon icon="add" size="medium" color="#0E4333" />Request
                  Changes
                </div>
                <div class="w-1/2" v-if="showEmergencyMedicationChanges">
                  <vs-textarea
                    label="Add your notes for next review."
                    v-model="emergencyMedicationChanges"
                  />
                  <vs-button
                    type="border"
                    color="primary"
                    @click="saveChangeRequest('emergency')"
                    >Send</vs-button
                  >
                </div>
              </div>
            </div>
          </div>
          <vs-divider />
          <div class="flex flex-no-wrap w-full mt-5">
            <div class="w-full">
              <div class="flex flex-no-wrap justify-between">
                <p class="text-base font-semibold">Clinic tour videos</p>
                <div
                  class="vs-con-loading__container"
                  style="text-align: center; position: relative"
                >
                  <label
                      class="vs-component vs-button h-12 text-center px-6 text-sm vs-button-primary vs-button-border flex items-center"
                      style="
                        border: 1px solid #cccccc;
                        display: inline-block;
                        width: 100%;
                        border-radius: 5px;
                        padding: 10px;
                        border: 1px solid rgba(var(--vs-primary),1);
                        color: rgba(var(--vs-primary),1);
                      "
                    >
                    <span>
                      <span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span>
                      <span class="vs-button-text vs-button--text"> Upload </span>

                        <input
                          type="file"
                          @change="uploadImage($event, 'videoTour')"
                          id="fridge-file-input"
                          accept="video/mp4,video/x-m4v,video/*"
                          hidden
                          multiple
                        />
                        <span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span>
                    </span>
                  </label>
                </div>
              </div>
              <div
                class="flex flex-wrap float-right justify-items-end"
                v-if="uploadErrors.includes('maxVidSize')"
              >
                <div
                  class="con-text-validation span-text-validation-danger vs-input--text-validation-span"
                  style="display: inline-block"
                >
                  <span class="span-text-validation"
                    >Upload size must be 200mb or less.</span
                  >
                </div>
              </div>
              <div class="flex flex-wrap w-full">
                <div
                  :key="index"
                  v-for="(image, index) in videos.videoTour"
                  class="max-w-full px-5 lg:w-1/2 sm:w-full"
                >
                  <video
                    class="w-full max-w-full"
                    height="480"
                    controls
                    preload="auto"
                    style="border-radius: 8px"
                  >
                    <source :src="image.videoLink" />
                  </video>
                  <div class="flex justify-between py-2 align-center">
                    <p>{{ formatDate(image.createdAt) }}</p>
                    <vs-button
                      size="lg"
                      color="danger"
                      class="text-right"
                      @click="
                        displayRemoveModal({
                          ...image,
                          fileType: 'video',
                          index: index,
                        })
                      "
                      >Remove</vs-button
                    >
                  </div>
                  <!-- <a :href="image.Location">{{
                      image.Location
                    }}</a> -->
                </div>
              </div>
              <div>
                <div>
                  <vs-list>
                    <vs-list-item
                      :key="index"
                      v-for="(note, index) in requestChanges.videoTour"
                      icon="radio_button_checked"
                      :subtitle="`${note.requestNote} -   ${formatDate(
                        note.createdAt
                      )}`"
                    >
                    </vs-list-item>
                  </vs-list>
                </div>

                <div
                  class="flex flex-no-wrap items-center w-full cursor-pointer"
                  style="color: #61676f"
                  @click="showVideoChanges = !showVideoChanges"
                >
                  <vs-icon icon="add" size="medium" color="#0E4333" />Request
                  Changes
                </div>

                <div class="w-1/2" v-if="showVideoChanges">
                  <vs-textarea
                    label="Add your notes for next review."
                    v-model="videoChanges"
                  />
                  <vs-button
                    type="border"
                    color="primary"
                    @click="saveChangeRequest('videoTour')"
                    >Save</vs-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="w-full mt-10">
          <p class="mb-1">
            {{ `Mitchels Cosmetics` }}
          </p>
          <p class="mb-1">
            {{ `Contact: 0412341234` }}
          </p>
          <p class="mb-1">
            {{ `Email: email@email.com` }}
          </p>
        </div> -->
        <!-- <div class="flex flex-no-wrap w-full mt-10">
          <div class="w-1/2 p-2">
            <p class="mb-1">Checklist (hs snippet)</p>
            <vs-textarea
              label="Prepopulate with a set of notes."
              v-model="checkList"
            />
          </div> -->
        <!-- <div class="w-1/2 p-2">
          <p class="mb-1">Handover notes for next month</p>
          <vs-textarea
            label="Add your notes for next review."
            v-model="checkList"
          />
          <div class="flex flex-no-wrap items-center" style="color: #61676f">
            <vs-icon icon="add" size="medium" color="#0E4333"> </vs-icon>Add
            video tour checklist for clinic
          </div>
        </div> -->
        <!-- </div> -->
        <!-- <div class="flex flex-no-wrap w-full mt-10">
          <ul>
            <li class="py-1">
              <vs-checkbox color="#0E4333" v-model="callCompleted"
                >Video call completed</vs-checkbox
              >
            </li>
            <li class="py-1">
              <vs-checkbox v-model="frigeTempAok">Fridge temps AOK</vs-checkbox>
            </li>
            <li class="py-1">
              <vs-checkbox disabled="true" v-model="videoCallAetcetcetc"
                >Video call aetcetcetc</vs-checkbox
              >
            </li>
            <li class="py-1">
              <vs-checkbox disabled="true" v-model="checkBox4"
                >Primary - inactive - disabled</vs-checkbox
              >
            </li>
          </ul>
        </div> -->
      </div>
    </vs-card>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import _ from "lodash";
import { mapActions } from "vuex";
import heic2any from "heic2any";
import moment from "moment";
import ProgressBar from '@/views/components/drugbook/ProgressBar.vue';
export default {
  components: {
    Carousel,
    Slide,
    ProgressBar
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
     monthCompliance: function () {
      return moment(this.complianceDetails.createdAt).subtract(1,'month').format('MM')
    },
    yearCompliance: function (){
      return moment(this.complianceDetails.createdAt).subtract(1,'month').format('YYYY')
    }
  },
  data: () => ({
    isDigitalDrubook: false,
    isStocktake: false,
    orgId: null,
    treatments: 0,
    page: 1,
    callCompleted: false,
    frigeTempAok: false,
    videoCallAetcetcetc: false,
    popupImageActivo: false,
    popUpDelete: false,
    showDrugChanges: false,
    showFridgeChanges: false,
    showStockChanges: false,
    showEmergencyMedicationChanges: false,
    frigeChanges: "",
    showVideoChanges: false,
    showGeneralChanges: false,
    videoChanges: "",
    stockChanges: "",
    drugChanges: "",
    emergencyMedicationChanges: "",
    generalNotesChanges: "",
    generalNotes: [],
    imagePreviewSrc: {
      from: "",
      Location: "",
      index: 0,
    },
    selectedVideo: null,
    checkList: "",
    status: "",
    requestChanges: {
      drug: [],
      fridge: [],
      stock: [],
      emergency: [],
      videoTour: [],
    },
    statusList: [
      { text: "Up next", value: "upNext" },
      { text: "Ready for review", value: "inReview" },
      { text: "Completed", value: "completed" },
      { text: "Skipped", value: "skipped" },
      { text: "Missed", value: "failed" },
      { text: "Change Requested", value: "changeRequested" },
    ],
    images: {
      drug: [],
      fridge: [],
      stock: [],
      emergency: [],
    },
    videos: {
      videoTour: [],
    },
    complianceID: "",
    complianceMonth: "",
    complianceDetails: {},
    previousComplianceDetails: {},
    previousRequestChanges: {
      drug: [],
      fridge: [],
      stock: [],
      emergency: [],
      videoTour: [],
    },
    uploadErrors: [],
    selectedFile: "",
    complianceDrugbook: [],
  }),
  methods: {
    ...mapActions("monthlyCompliance", [
      "fetchDetails",
      "uploadFiles",
      "downloadPdf",
      "addSuperAdminChangeRequest",
      "addSuperAdminGeneralNotes",
      "updateVideos",
      "updatePhotos",
      "approveRejectCompliance",
      "getPreviousMonthCompliance",
      "fetchUnsetProducts",
      "fetchClinicDrugbookProducts",
      "fetchStocktakeFlagFeature",
    ]),
    ...mapActions("clinic", ["fetchClinicsDetail"]),
    ...mapActions("drugbook", [
      "fetchDrugEntries",
      "fetchDrugEntriesPDF",
    ]),
    async getClinicDetail(id) {
      const res = await this.fetchClinicsDetail(id);
      // this.orgId = res.data.data.organizationDetail._id
      // await this.getStocktakeFlagFeature(res.data.data.organizationDetail._id);
    },
    // async getUnsetProducts(clinicId) {
    //   this.$vs.loading();
    //   try{
    //     const res = await this.fetchUnsetProducts({clinicId: clinicId });
    //     if(res.data.data.length > 0){
    //         this.totalTreatmentsWithNoEntry = res.data.data[0].totalTreatmentsWithNoEntry
    //     }
    //     this.$vs.loading.close();
    //   }
    //   catch(e){
    //     console.log(e)
    //   }
    // },
    async getStocktakeFlagFeature(orgId) {
      try {
        const res = await this.fetchStocktakeFlagFeature({
          orgId: orgId,
          clinicId: this.complianceDetails.clinicId,
        });
        this.isStocktake = res.data.data.status;
      } catch (e) {
        return;
      }
    },
    async getClinicDrugbookProducts() {
      try {
        const res = await this.fetchClinicDrugbookProducts({
          clinicId: this.complianceDetails.clinicId,
          complianceId: this.complianceID,
        });
        this.complianceDrugbook = res.data.data;
      } catch (e) {
        return;
      }
    },
    rescheduleBooking() {
      this.$router.push({
        name: "superAdminComplianceReschedule",
        params: { clinicId: this.complianceID },
      });
    },
    redirectToComplianceList() {
      this.popupActivo = false;
      this.$router.push({ name: "compliance" }).catch(() => {});
    },
    async approveReject(status) {
      const data = {
        payload: {
          status: status,
        },
        clinicId: this.complianceID,
      };
      await this.approveRejectCompliance(data);
    },

    getMonthValue(day) {
      const months = [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ];
      const month = moment(day).month();
      return months[month];
    },
    getStatus(status) {
      return _.find(this.statusList, ["value", status]).text;
    },
    async getDetails(complianceID) {
      const res = await this.fetchDetails({ clinicId: complianceID });
      if (res.data.data.length > 0) {
        this.complianceDetails = res.data.data[0];
        await this.getClinicDetail(res.data.data[0].clinicId);
        // await this.getUnsetProducts(res.data.data[0].clinicId)
        if (res.data.data[0].bookingDate) {
          this.complianceDetails.bookingDate = `
          ${moment(res.data.data[0].bookingDate).format("MMMM DD YYYY")} -
          ${moment(res.data.data[0].bookingDate).format("hh:mm")} -
          ${moment(res.data.data[0].bookingDate)
            .add(30, "minutes")
            .format("hh:mm a")}
          `;
        }
        this.complianceMonth = moment(res.data.data[0].createdAt).subtract(1,'month').format(
          "MMMM YYYY"
        );

        this.images.drug = _.filter(res.data.data[0].uploadedPhotos, [
          "group",
          "drugbook",
        ]).map((item) => item);
        this.images.fridge = _.filter(res.data.data[0].uploadedPhotos, [
          "group",
          "fridgetemperature",
        ]).map((item) => item);
        this.images.stock = _.filter(res.data.data[0].uploadedPhotos, [
          "group",
          "stockPhotos",
        ]).map((item) => item);
        this.videos.videoTour = _.filter(res.data.data[0].uploadedVideos, [
          "group",
          "videoTour",
        ]).map((item) => item);
        this.images.emergency = _.filter(res.data.data[0].uploadedPhotos, [
          "group",
          "emergencyPhotos",
        ]).map((item) => item);

        this.requestChanges.drug = _.filter(res.data.data[0].changeRequests, [
          "group",
          "drug",
        ]).map((item) => item);
        this.requestChanges.fridge = _.filter(res.data.data[0].changeRequests, [
          "group",
          "fridge",
        ]).map((item) => item);
        this.requestChanges.stock = _.filter(res.data.data[0].changeRequests, [
          "group",
          "stock",
        ]).map((item) => item);
        this.requestChanges.videoTour = _.filter(
          res.data.data[0].changeRequests,
          ["group", "videoTour"]
        ).map((item) => item);
        this.requestChanges.emergency = _.filter(
          res.data.data[0].changeRequests,
          ["group", "emergency"]
        ).map((item) => item);
        this.generalNotes = res.data.data[0].generalNotes;
      }
    },
    async getPreviousMonthComplianceDetails() {
      const res = await this.getPreviousMonthCompliance({
        clinicId: this.complianceDetails.clinicId,
        month: this.getMonthValue(this.complianceDetails.createdAt),
      });
      if (res.data.data.docs.length > 0) {
        this.previousComplianceDetails = res.data.data.docs[0];
        this.previousRequestChanges.drug = _.filter(
          res.data.data.docs[0].changeRequests,
          ["group", "drug"]
        ).map((item) => item);
        this.previousRequestChanges.fridge = _.filter(
          res.data.data.docs[0].changeRequests,
          ["group", "fridge"]
        ).map((item) => item);
        this.previousRequestChanges.stock = _.filter(
          res.data.data.docs[0].changeRequests,
          ["group", "stock"]
        ).map((item) => item);
        this.previousRequestChanges.videoTour = _.filter(
          res.data.data.docs[0].changeRequests,
          ["group", "videoTour"]
        ).map((item) => item);
        this.previousRequestChanges.emergency = _.filter(
          res.data.data.docs[0].changeRequests,
          ["group", "emergency"]
        ).map((item) => item);
      }
    },
    async saveImages() {
      try {
        this.$vs.loading();
        this.approveReject(this.complianceDetails.status);

        if (
          this.images.drug.length > 0 ||
          this.images.fridge.length > 0 ||
          this.images.stock.length > 0 ||
          this.images.emergency.length > 0
        ) {
          const drugbook = this.images.drug;
          const fridgetemperature = this.images.fridge;
          const stockPhotos = this.images.stock;
          const emergencyPhotos = this.images.emergency;

          const data = {
            payload: {
              uploadedPhotos: [
                ...drugbook,
                ...fridgetemperature,
                ...stockPhotos,
                ...emergencyPhotos,
              ],
            },
            clinicId: this.complianceID,
          };
          await this.updatePhotos(data);
        }
        if (this.videos.videoTour.length > 0) {
          const videoTour = this.videos.videoTour;
          const videoData = {
            payload: {
              uploadedVideos: [...videoTour],
            },
            clinicId: this.complianceID,
          };
          await this.updateVideos(videoData);
        }
        setTimeout(() => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Success",
            text: "Monthly Compliance successfully saved.",
            color: "success",
          });
        }, 1000);

        // }
      } catch (error) {
        console.error(error);
        setTimeout(() => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Failed",
            text: "Failed to update Monthly Compliance. Try again!",
            color: "danger",
          });
        }, 1000);
      }
    },
    ImagePreview(image) {
      const index = _.findIndex(this.images[image.from], [
        "photoLink",
        image.Location,
      ]);
      image.index = index;
      this.popupImageActivo = true;
      this.imagePreviewSrc = image;
    },
    async genratePdfReport(){
      this.$vs.loading()
      try {
        const res = await this.fetchDrugEntriesPDF({
          page: 1,
          limit: Number.MAX_SAFE_INTEGER,
          selectedClinic: this.complianceDetails.clinicId,
          isCurrentDateMinus30Days: true,
        });
        res.data.data.photoLink = res.data.data.Location;
        res.data.data.group = 'drugbook'
        this.images['drug'] = [
          res.data.data,
        ];
        this.$vs.notify({
            title: "Success",
            text: "Exported PDF from drugbook Updated.",
            color: "success",
          });

      } catch (error) {
        this.$vs.notify({
            title: "Failed",
            text:   "Export from drugbook. Try again!",
            color: "danger",
          });
      }
      //uncomment to auto download expported PDF
      // await this.DownloadPdf({
      //   url: res.data.data.Location,
      //   name: `${this.complianceDetails.clinicName}-${this.complianceMonth}-${moment(this.complianceDetails.updatedAt).format("YYYY")}-drugbook`
      // })
      this.$vs.loading.close()
    },
    async DownloadPdf({ url, name }) {
      const res = await this.downloadPdf(url);
      const blob = new Blob([res.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = name.includes('Monthly Compliance') ? `${name} - ${moment(this.complianceDetails.createdAt).subtract(1,'month').format('MMMM')}` : `${name}-monthly-compliance.pdf`;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    back() {
      const photoLink =
        this.images[this.imagePreviewSrc.from][this.imagePreviewSrc.index - 1]
          .photoLink;
      if (this.imagePreviewSrc.index > 0 && photoLink.indexOf("pdf") < 0) {
        this.imagePreviewSrc.index = this.imagePreviewSrc.index - 1;
      }
    },
    forward() {
      const photoLink =
        this.images[this.imagePreviewSrc.from][this.imagePreviewSrc.index + 1]
          .photoLink;
      if (
        this.images[this.imagePreviewSrc.from].length - 1 >
        this.imagePreviewSrc.index
      ) {
        this.imagePreviewSrc.index = this.imagePreviewSrc.index + 1;
      }
    },
    getDownloadName(image) {
      return `${this.imagePreviewSrc.from}${image.timestamp}.png`;
    },
    async removePreview(image) {
      if (image.from === "videoTour") {
        this.$vs.loading();
        const videoIndex = _.findIndex(this.videos[image.from], [
          "videoLink",
          image.Location,
        ]);
        this.videos[image.from].splice(videoIndex, 1);
        if (this.videos.videoTour.length > 0) {
          const videoTour = this.videos.videoTour;
          const videoData = {
            payload: {
              uploadedVideos: [...videoTour],
            },
            clinicId: this.complianceID,
          };
          const res = await this.updateVideos(videoData);
          if (res.status == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Monthly Compliance successfully saved.",
              color: "success",
            });
          }
          this.selectedVideo = null;
          this.selectedFile = "";
          this.$vs.loading.close();
        }
      } else {
        const index = _.findIndex(this.images[image.from], [
          "photoLink",
          image.Location,
        ]);
        this.images[this.imagePreviewSrc.from].splice(index, 1);
        this.imagePreviewSrc = {
          from: "",
          Location: "",
          index: 0,
        };
        this.popupImageActivo = false;
        this.saveImages();
      }
      this.popUpDelete = false;
    },
    displayRemoveModal(value) {
      this.selectedFile = value.fileType;
      this.selectedVideo = { ...value };
      this.popUpDelete = true;
    },
    formatDate(value, full = true) {
      const date = moment(value);
      if (full) {
        return date.format("MMMM DD YYYY");
      }
      return date.format("MMMM YYYY");
    },
    async saveGeneralNotes() {
      try {
        this.$vs.loading();
        let note = this.generalNotesChanges;
        if (note == "") {
          this.$vs.notify({
            title: "Failed",
            text: "Internal notes field must not be empty. Try again!",
            color: "danger",
          });
          setTimeout(async () => {
            this.$vs.loading.close();
            await this.getDetails(this.complianceID);
          }, 500);
          return;
        }
        await this.addSuperAdminGeneralNotes({
          clinicId: this.complianceDetails.clinicId,
          payload: {
            generalNotes: {
              note,
            },
          },
        });
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Success",
          text: "General Notes Sent successfully.",
          color: "success",
        });
        this.generalNotesChanges = "";
        setTimeout(async () => {
          await this.getDetails(this.complianceID);
        }, 500);
      } catch (err) {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Failed",
          text: "Failed to send General Notes. Try again!",
          color: "danger",
        });
      }
    },

    async saveChangeRequest(group) {
      try {
        this.$vs.loading();
        let changeNotes = "";
        if (group === "drug") {
          changeNotes = this.drugChanges;
          this.drugChanges = "";
        } else if (group === "fridge") {
          changeNotes = this.fridgeChanges;
          this.fridgeChanges = "";
        } else if (group === "stock") {
          changeNotes = this.stockChanges;
          this.stockChanges = "";
        } else if (group === "videoTour") {
          changeNotes = this.videoChanges;
          this.videoChanges = "";
        } else if (group === "emergency") {
          changeNotes = this.emergencyMedicationChanges;
          this.emergencyMedicationChanges = "";
        }

        if (changeNotes == "" || !changeNotes) {
          this.$vs.notify({
            title: "Failed",
            text: "Request change field must not be empty. Try again!",
            color: "danger",
          });
          setTimeout(async () => {
            this.$vs.loading.close();
          }, 500);
          return;
        }

        await this.addSuperAdminChangeRequest({
          complianceId: this.complianceID,
          payload: {
            requestChange: {
              group: group,
              requestNote: changeNotes,
            },
          },
        });
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Success",
          text: "Change Request Sent successfully.",
          color: "success",
        });
        setTimeout(async () => {
          await this.getDetails(this.complianceID);
        }, 500);
      } catch (err) {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Failed",
          text: "Failed to send Change Request. Try again!",
          color: "danger",
        });
      }
    },
    async uploadImage(event, imagefor = "") {
      try {
        let files = new FormData();
        const fileArray = Object.keys(event.target.files).map((item) => item);
        // fileArray.forEach((item) => {
        //   files.append(`files[${item}]`, event.target.files[item]);
        // });
        for (var i = 0; i < fileArray.length; i++){
          if(event.target.files[[fileArray[i]]]["type"].startsWith("image/heic")){
            const blob = new Blob([event.target.files[fileArray[i]]], { type: "image/heic" })
            const result = await heic2any({
              blob,
              toType: "image/jpeg",
              quality: 1,
            })
            var newfile = new File([result], `files[${fileArray[i]}].jpeg`);
            files.append(`files[${fileArray[i]}]`, newfile);
          }
          else{
            files.append(`files[${fileArray[i]}]`, event.target.files[fileArray[i]]);
          }
        }
        if (
          (imagefor !== "videoTour" &&
            event.target.files[0]["type"].startsWith("image")) ||
          event.target.files[0]["type"].indexOf("pdf") > -1 ||
          (imagefor === "videoTour" &&
            event.target.files[0]["type"].startsWith("video"))
        ) {
          if (event.target.files[0]["size"] < 209715200) {
            this.$vs.loading({
              text: "Please wait while we're uploading your file(s)...",
            });
            this.uploadErrors = _.pullAll(
              [event.target.files[0]["size"] < 209715200 && undefined],
              [undefined]
            );
            const res = await this.uploadFiles(files);
            if (imagefor === "videoTour") {
              this.videos[imagefor] = [
                ...this.videos[imagefor],
                ...res.data.map((item) => {
                  item.videoLink = item.Location;
                  item.group = "videoTour";
                  return item;
                }),
              ];
            } else {
              this.images[imagefor] = [
                ...this.images[imagefor],
                ...res.data.map((item) => {
                  item.photoLink = item.Location;
                  item.group =
                    imagefor === "drug"
                      ? "drugbook"
                      : imagefor === "fridge"
                      ? "fridgetemperature"
                      : imagefor === "stock"
                      ? "stockPhotos"
                      : imagefor === "emergency"
                      ? "emergencyPhotos"
                      : "";
                  return item;
                }),
              ];
            }
            this.$vs.loading.close();
          } else {
            this.uploadErrors = _.pullAll(
              [
                event.target.files[0]["size"] > 209715200
                  ? "maxVidSize"
                  : undefined,
              ],
              [undefined]
            );
            this.$vs.notify({
              title: "Upload Failed",
              text: "File exceeds maximum file size",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
        } else {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Upload Failed",
            text: "File Format Not Supported",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      } catch (err) {
        this.$vs.loading.close();
        if (err && err.status === 415) {
          this.$vs.notify({
            title: "Upload Failed",
            text: "File Format Not Supported",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        } else {
          this.$vs.notify({
            title: "Upload Failed",
            text: "Please try again later.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      }
    },
    getStatusData(data) {
      const status = {
        upNext: "Up Next",
        failed: "Missed",
        skipped: "Skipped",
        inReview: "Ready for Review",
        changeRequested: "Change Requested",
        completed: "Completed",
      };

      return status[data];
    },
  },
  watch: {
    status: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.complianceDetails.status = newVal;
        this.status = newVal;
      }
    },
  },
  async created() {
    this.complianceID = this.$route.params.clinicId;
    await this.getDetails(this.complianceID);
    await this.getClinicDrugbookProducts();
    const res = await this.fetchDrugEntries({
      page: 1,
      limit: Number.MAX_SAFE_INTEGER,
      selectedClinic: this.complianceDetails.clinicId,
    });
    this.isDigitalDrubook = res.data.data.docs.length > 0;
    this.genratePdfReport()
    await this.getPreviousMonthComplianceDetails();
    // for (let index = 0; index < 9; index++) {
    //   this.images.drug.push({
    //     index: index,
    //     Location: `https://picsum.photos/400/400?image=2${index}`,
    //   });
    //   this.images.fridge.push({
    //     index: index,
    //     Location: `https://picsum.photos/400/400?image=2${index}`,
    //   });
    //   this.images.stock.push({
    //     index: index,
    //     Location: `https://picsum.photos/400/400?image=2${index}`,
    //   });
    // }
  },
};
</script>

<style>
.hover-scale:hover {
  transform: scale(1.1);
  transform-origin: 50% 50%;
}

.examplex button.btnx {
  margin-left: 10px !important;
  border-radius: 5px 0px 0px 5px;
}

.examplex button.btn-drop {
  border-radius: 0px 5px 5px 0px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.background-gray {
  background-color: #d9d9d9;
}

.history-container {
  width: 402px;
}

.vs-image--img {
  background-position: 50%;
  padding-bottom: 100%;
  background-size: cover;
  display: block;
  position: relative;
  object-fit: contain;
}

.upnext,
.inreview {
  background-color: #e5f8ee;
  color: #84cdb8;
}

.skipped {
  background-color: #e2e8e6;
  color: #074230;
}

.failed {
  background-color: #fbe4e4;
  color: #ea5455;
}

.changerequested {
  background-color: #ff9f43;
  color: #fff2e3;
}

.completed {
  background-color: #e2e8e6;
  color: #074230;
}

.deleteContainer > .vs-popup {
  width: 350px;
  height: auto;
}

.resizeImgPopUp > .vs-popup {
  width: auto;
}

.con-vs-popup > .vs-popup {
  max-height: auto;
}

.m-drugbook-wrapper {
  padding: 8px 6px;
}

.m-drugbook-name {
  color: #0e4333;
  font-weight: 600;
  font-size: 13.5px;
  line-height: 130%;
  margin-bottom: 8px;
}

.m-drugbook-row {
  display: flex;
  margin-bottom: 8px;
}

.m-drugbook-label {
  flex: 1;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  color: #6e6b7b;
}

.m-drugbook-values {
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  color: #6e6b7b;
}
.m-drugbook-notes {
  width: 50%;
  font-weight: 500;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 4px;
  text-align: right;
  color: #6e6b7b;
}

.m-drugbook-variance {
  color: #e02020;
}
@media (min-width:700px){
  .progress-bar-width{
    width: 30rem;
  }

}

</style>
